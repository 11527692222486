import * as React from "react";
import {
  Box,
  Card,
  Button,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Autocomplete,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import CustomSnackbar from "../../utils/CustomSnackbar";

// hooks
import useSettings from "../../hooks/useSettings";

// components

const EditDocBasicinfo = () => {
  const [fn, setFN] = useState("");
  const [ln, setLN] = useState("");
  const [em, setEM] = useState("");
  const [mob, setMB] = useState("");
  const [status, setSt] = useState("");
  const [gen, setGen] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [qua, setQua] = useState("")
  const [pic, setPic] = useState("")
  const [dept_name, setDN] = useState()
  const [about, setAb] = useState("")
  const [web, setWeb] = useState("")
  const [doj, setDJ] = useState("")
  const location = useLocation();
  
  const [openSnack, setOpenSnack] = useState({
    open: false,
    severity: null,
    message: null,
  });
  const [bloodgroup, setBG] = useState("")
  const [data, setData] = useState([]);
  console.log("sdsd");
  console.log(location.state.id);
  useEffect(() => {
    axios
      .post(
        "https://test1.seekinterns.com/fetchdoctor",JSON.stringify({ id: location.state.id })
      )
      .then((response) => {
        console.log(location.state.id)
        console.log("htiie");
        console.log(response.data)
        setData(response.data);
        setFN(response.data.firstname);
        setLN(response.data.lastname);
        setEM(response.data.email);
        setMB(response.data.mobile);
        setSt(response.data.status);
        setGen(response.data.gender)
        setBG(response.data.bloodgroup)
        setDep(response.data.department_id)
        setDN(response.data.dept_name)
        setQua(response.data.qualifications)
        setPic(response.data.picture)
        setAb(response.data.about)
        setWeb(response.data.website_link)
        setDJ(response.data.date_of_joining)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [users, setUsers] = useState([]);
  const [dep, setDep] = useState('');
 
  useEffect(() => {
    fetch('https://test1.seekinterns.com/departments')
      .then(response => response.json())
      .then(data => setUsers(data))
      .catch(error => console.error('Error:', error));
  }, []);
  
  const handleFormSubmit = async (values) => {
    await axios
      .put("https://test1.seekinterns.com/insertapieditdoctor", {
        id: id,
        firstname: fn,
        lastname: ln,
        email: em,
        mobile: mob,
        status: status,
        gender: gen,
        bloodgroup: bloodgroup,
        department_id: dep,
        qualifications: qua,
        picture: pic,
        about: about,
        website_link: web,
        dateofjoining: doj,
      })
      .then((res) => {
        setOpenSnack({
          open: true,
          severity: "success",
          message: "Doctor Updated Successfully",
        });
        setTimeout(() => {
          navigate("../doctors");
        }, 2000);
      })
      .catch((err) => {
        setOpenSnack({
          open: true,
          severity: "error",
          message: "Something went wrong !",
        });
      });
  };

  const { open, severity, message } = openSnack;

  return (
    <>
      {CustomSnackbar(open, severity, message, ["bottom", "right"])}
      <Box m="20px">
        <Card>
          <Box m="20px">
            <Helmet>
              <title> Add Doctor | Kaizen Digital </title>
            </Helmet>
          </Box>
          <Box m="20px">
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": {
                        gridColumn: isNonMobile ? undefined : "span 4",
                      },
                    }}
                  >
                    <TextField
                      fullWidth
                      type="text"
                      label="First Name1 *"
                      onBlur={handleBlur}
                      onChange={e => setFN(e.target.value)}
                      value={fn}
                      name="firstName"
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="Last Name *"
                      onBlur={handleBlur}
                      onChange={e => setLN(e.target.value)}
                      value={ln}
                      name="lastName"
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="Email *"
                      onBlur={handleBlur}
                      onChange={e => setEM(e.target.value)}
                      value={em}
                      name="email"
                      sx={{ gridColumn: "span 1"
                    }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="Enter Mobile Number *"
                      onBlur={handleBlur}
                      onChange={e => setMB(e.target.value)}
                      value={mob}
                      name="contact"
                      sx={{ gridColumn: "span 1" }}
                    />
                   
                     <TextField
                      fullWidth
                      type="text"
                      label="Date of Joining (MM/DD/YYYY)"
                      onBlur={handleBlur}
                      onChange={e => setDJ(e.target.value)}
                      value={doj}
                      name="date_of_joining"
                      sx={{ gridColumn: "span 1" }}
                    />

                    <FormControl fullWidth>
                      <InputLabel id="status">Status</InputLabel>
                      <Select
                        labelId="Status"
                        id="status"
                        name="status"
                        label="Status"
                        onChange={e => setSt(e.target.value)}
                        sx={{ gridColumn: "span 2" }}
                        value={status}
                      >
                        <MenuItem value={1}>Enabled</MenuItem>
                        <MenuItem value={0}>Disabled</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="bloodgroup">Blood Group</InputLabel>
                      <Select
                        labelId="bloodgroup"
                        id="bloodgroup"
                        value={bloodgroup}
                        label="bloodgroup"
                        sx={{ gridColumn: "span 1" }}
                        name="bloodgroup"
                        onChange={(e)=>setBG(e.target.value)}
                      >
                        <MenuItem value={0}>A+</MenuItem>
                        <MenuItem value={1}>A-</MenuItem>
                        <MenuItem value={2}>B+</MenuItem>
                        <MenuItem value={3}>B-</MenuItem>
                        <MenuItem value={4}>O+</MenuItem>
                        <MenuItem value={5}>O-</MenuItem>
                        <MenuItem value={6}>AB+</MenuItem>
                        <MenuItem value={7}>AB-</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="gender">Gender</InputLabel>
                      <Select
                        labelId="Gender"
                        id="gender"
                        name="gender"
                        label="Gender"
                        onChange={e => setGen(e.target.value)}
                        sx={{ gridColumn: "span 2" }}
                        value={gen}
                      >
                        <MenuItem value={1}>Male</MenuItem>
                        <MenuItem value={2}>Female</MenuItem>
                        <MenuItem value={3}>Other</MenuItem>
                      </Select>
                    </FormControl>

                 
{/* STATIC DEPARTMENT DROP-DOWN */}
                    {/* <FormControl fullWidth>
                      <InputLabel id="department_id">Department</InputLabel>
                      <Select
                        labelId="department_id"
                        id="department_id"
                        value={dep}
                        label="department_id"
                        sx={{ gridColumn: "span 2" }}
                        name="department_id"
                        onChange={(e)=> setDep(e.target.value)}
                      >
                        <MenuItem value={3}>General</MenuItem>
                        <MenuItem value={4}>Psych PT (NP) </MenuItem>
                        <MenuItem value={6}>General Neuro</MenuItem>
                        <MenuItem value={85}>TBI PT (NP)</MenuItem>
                        <MenuItem value={86}>ADHD (NP)</MenuItem>
                        <MenuItem value={89}>Facial Pain</MenuItem>
                        <MenuItem value={90}>Headache</MenuItem>
                        <MenuItem value={91}>Medication Management</MenuItem>
                        <MenuItem value={92}>Movement Disorder</MenuItem> 
                        <MenuItem value={93}>Headache and Pain Clinic</MenuItem>
                      </Select>
                    </FormControl> */}
                    {/* DYNAMIC DEPARTMENT DROPDOWN */}
        {/* <Autocomplete
          onChange={(event, value) => handlename(event, value)}
          // onChange={(e)=>setDep(e.target.value)}
          id="name"
          name="department_id"
          getOptionLabel={(name) => `${name}`}
          options={name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          // defaultValue={datag.filter((state) => state.id === dep)}
          noOptionsText={"No Available Data"}
          // defaultValue={"Hello"}
          renderOption={(props, name) => (
            <Box component="li" {...props}
            key={name.name}
            value={name.id}
            >
              {name}
            </Box>
          )}
         
          renderInput={(params) => (
          <TextField {...params} label="Department" />
          )}
          // defaultValue={dept_name} //dep//dept_name
        /> */}

                    {/* <FormControl fullWidth>
                      <InputLabel id="department_id">Department</InputLabel>
                      <Select
                        labelId="department_id"
                        id="department_id"
                        value={dep}
                        label="department_id"
                        sx={{ gridColumn: "span 2" }}
                        name="department_id"
                        onChange={(e)=> setDep(e.target.value)}
                      >
                        <MenuItem value={dep}>{dept_name}</MenuItem>
                        //{options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                               ))}//
                      </Select>
                    </FormControl> */}

<select value={dep} onChange={e => setDep(e.target.value)} style={{borderRadius:"5px", borderColor:"#CDCDCD", color:"#5A5A5A"}}>
    {users.map(user => (
      <option key={user.id} value={user.id}>
        {user.name}
      </option>
    ))}
  </select>
          <TextField
                      fullWidth
                      type="text"
                      label="Qualifications *"
                      onBlur={handleBlur}
                      onChange={e => setQua(e.target.value)}
                      value={qua}
                      name="qualifications"
                      sx={{ gridColumn: "span 1" }}
                    />

                    <TextField
                      fullWidth
                      type="text"
                      label="About"
                      onBlur={handleBlur}
                      onChange={e => setAb(e.target.value)}
                      value={about}
                      name="about"
                      sx={{ gridColumn: "span 2" }}
                    />

                    <TextField
                      fullWidth
                      type="text"
                      label="Picture"
                      onBlur={handleBlur}
                      onChange={e => setPic(e.target.value)}
                      value={pic}
                      name="picture"
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      label="Website Link"
                      onBlur={handleBlur}
                      onChange={e => setWeb(e.target.value)}
                      value={web}
                      name="website_link"
                      sx={{ gridColumn: "span 2" }}
                    />
            
                  </Box>
                  <br />
                 
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button onClick={handleFormSubmit} type="submit" variant="contained" sx={{color:"white"}}>
                      SAVE 
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Card>
      </Box>
    </>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  password: yup
    .string()
    .required("required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol"),
});
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  dateofbirth: "",
  status: "",
  bloodgroup: "",
  gender: "",
  password: "",
  department: [],
};

export default EditDocBasicinfo;
