import { useState } from 'react';
import { Link } from 'react-router-dom';

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import BallotIcon from '@mui/icons-material/Ballot';
import useDetails from "src/hooks/useDetails";

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export default function QuickLinks() {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const userDetails = useDetails();

  let quicklinks; 

  if (userDetails?.rolesname == "Care Admin Manager" ) {
    quicklinks = <div>
    <MenuItem >
    <Link to="/dashboard/patientregistration/addpatient" style={{textDecoration: 'none'}}>
     New Patient
     </Link>
    </MenuItem>
    <MenuItem >
    <Link to="/dashboard/insurance" style={{textDecoration: 'none'}}>
     Insurance Details
     </Link>
    </MenuItem>
    <MenuItem >
    <Link to="/dashboard/departments" style={{textDecoration: 'none'}}>
     Departments
     </Link>
    </MenuItem>
    <MenuItem >
    <Link to="/dashboard/doctors" style={{textDecoration: 'none'}}>
     Doctors
     </Link>
    </MenuItem>
    </div>
  } else if (userDetails?.rolesname == "Insurance") {
    quicklinks = <div>
    <MenuItem >
    <Link to="/dashboard/validation" style={{textDecoration: 'none'}}>
    Validation
    </Link>
    </MenuItem>
    </div>
  } else {
    quicklinks = <div>
    <MenuItem >
     <Link to="/dashboard/myprofile" style={{textDecoration: 'none'}}>
      My Profile
      </Link>
     </MenuItem>
     <MenuItem >
     <Link to="dashboard/myhealthrecord/questionnaires" style={{textDecoration: 'none'}}>
      My Health Record
      </Link>
     </MenuItem>
 </div>
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
            },
          }),
        }}
      >
        <BallotIcon />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            Quick Links
          </Typography>
         
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
      {quicklinks}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

       
      </MenuPopover>
    </>
  );
}