import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useDetails from "src/hooks/useDetails";
import axios from "axios";

// const ROLES = {
//   Care_Admin_Manager: "84825",
//   Care_Admin: "23582",
//   Patient: "78484",
//   Patient_Guardian: "60281",
//   Insurance: "08364",
//   Doctor: "13735",
// };

const Protected = () => {
  const details = useDetails();
  const location = useLocation();

  const requestPagesUrls = async () => {
    try {
      const response = await axios.post(
        "https://qakdci.seekinterns.com/2222/permission",
        { id: details?.roles[0] }
      );
      return response?.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (details?.token) {
      requestPagesUrls().then((res) => {
        const localData = JSON.parse(localStorage.getItem("user"));
        localData.permission = res;
        Object.keys(details?.apiData).length && details?.setApiData(localData);
        localStorage.setItem("user", JSON.stringify(localData));
      });
    }
  }, []);

  return details?.token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
export default Protected;