import * as React from "react";
import PropTypes from "prop-types";
import { m } from "framer-motion";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import Logo from "./Logo";
import ProgressBar from "./ProgressBar";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: "100%",
  height: "100%",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
};

export default function LoadingScreen({ isDashboard, ...other }) {
  return (
    <>
      <ProgressBar />
      <LoadingTemplate loading={isDashboard} />
      {!isDashboard && (
        <>
          <RootStyle {...other}>
            <m.div
              initial={{ rotateY: 0 }}
              animate={{ rotateY: 360 }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                repeatDelay: 1,
                repeat: Infinity,
              }}
            >
              <Logo disabledLink sx={{ width: 64, height: 64 }} />
            </m.div>

            <Box
              component={m.div}
              animate={{
                scale: [1.2, 1, 1, 1.2, 1.2],
                rotate: [270, 0, 0, 270, 270],
                opacity: [0.25, 1, 1, 1, 0.25],
                borderRadius: ["25%", "25%", "50%", "50%", "25%"],
              }}
              transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}
              sx={{
                width: 100,
                height: 100,
                borderRadius: "25%",
                position: "absolute",
                border: (theme) =>
                  `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
              }}
            />

            <Box
              component={m.div}
              animate={{
                scale: [1, 1.2, 1.2, 1, 1],
                rotate: [0, 270, 270, 0, 0],
                opacity: [1, 0.25, 0.25, 0.25, 1],
                borderRadius: ["25%", "25%", "50%", "50%", "25%"],
              }}
              transition={{
                ease: "linear",
                duration: 3.2,
                repeat: Infinity,
              }}
              sx={{
                width: 120,
                height: 120,
                borderRadius: "25%",
                position: "absolute",
                border: (theme) =>
                  `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
              }}
            />
          </RootStyle>
        </>
      )}
    </>
  );
}

export function LoadingTemplate(props) {
  const { loading = false } = props;
  return (
    <>
      {loading && (
        <>
          <Typography component="div" variant={"h3"}>
            <Skeleton />
          </Typography>
          <Typography component="div" variant={"body"} width="80%">
            <Skeleton />
          </Typography>
          <Card sx={{ maxWidth: "100%", my: 2 }}>
            <Skeleton
              sx={{ height: 250 }}
              animation="wave"
              variant="rectangular"
            />
          </Card>
          <Typography component="div" variant={"body"}>
            <Skeleton />
          </Typography>
          <Typography component="div" variant={"body"}>
            <Skeleton />
          </Typography>
          <Typography component="div" variant={"body"} width="80%">
            <Skeleton />
          </Typography>
        </>
      )}
    </>
  );
}

LoadingTemplate.propTypes = {
  loading: PropTypes.bool,
};
